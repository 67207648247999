<template>

</template>

<script setup>
  import { showToast } from "vant";
  import i18n from "@/i18n";
  import { useRouter } from 'vue-router';

  const Web3Modal = window.Web3Modal.default;
  const WalletConnectProvider = window.WalletConnectProvider.default;

  // Web3modal instance
  let web3Modal, network, provider, selectedAccount;
  let injectedWeb3 = null;
  const infura_key = 'c3e254116c559ae5e58746e69fb38787';
  let provider1, signer;
  let gasPrice, usdtContract, landContract, amaContract, blockNftContract, conContract;

  const router = useRouter()
  const address = sessionStorage.getItem('address')
  if(address) {
    init()
  }

  const emits = defineEmits(['getContract'])
  async function init() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          bridge: "https://uniswap.bridge.walletconnect.org",
          rpc: {
            56: 'https://bsc-dataseed.binance.org/',
            66: 'https://exchainrpc.okex.org',
            97: 'https://data-seed-prebsc-1-s3.binance.org:8545/',
            137: 'https://polygon-rpc.com/',
          },
          // Mikko's test key - don't copy as your mileage may vary
          infuraId: infura_key,
        }
      },
    };
    web3Modal = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });
    try {
      provider = await web3Modal.connect();
      provider.enable()
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      alert("cannot connect wallet");
      return;
    }
    // Subscribe to accounts change
    provider.on("accountsChanged", async (accounts) => {
      await fetchAccountData();
    });
    // Subscribe to chainId change
    provider.on("chainChanged", async (chainId) => {
      await fetchAccountData();
    });
    // Subscribe to networkId change
    provider.on("networkChanged", async (networkId) => {
      await fetchAccountData();
    });
    provider.on("disconnect", async (code, reason) => {
      console.log(code, reason);
      await onDisconnect();
    });
    await fetchAccountData(provider);
  }

  async function fetchAccountData() {
    const web3 = new Web3(provider);
    injectedWeb3 = web3;
    const accounts = await web3.eth.getAccounts();
    selectedAccount = accounts[0];
    network = await web3.eth.net.getId();
    console.log(network + ': ' + selectedAccount);
    if (network != 56) {
      showToast(i18n.global.t('wTips'))
      await onDisconnect();
    } else {
      sessionStorage.setItem('address', selectedAccount)
      gasPrice = await web3.eth.getGasPrice();
      provider1 = new ethers.providers.Web3Provider(web3.currentProvider);
      signer = provider1.getSigner();
      usdtContract = new ethers.Contract(contractConfig.usdtAddress, usdtAbi, signer);
      amaContract = new ethers.Contract(contractConfig.amaAddress, amaAbi, signer);
      landContract = new ethers.Contract(contractConfig.landAddress, landAbi, signer);
      blockNftContract = new ethers.Contract(contractConfig.blockNftAddress, blockNftAbi, signer);
      conContract = new ethers.Contract(contractConfig.conAddress, conAbi, signer);
      emits('getContract', {
        address: selectedAccount,
        blockNftContract,
        conContract,
        gasPrice
      })
      /*context.emit('getContract', {
        usdtContract,
        amaContract,
        landContract,
        blockNftContract,
        conContract,
        gasPrice,
        signer
      })*/

      /*// 注册
      register({ address: address.value }).then(res => {
        getUserInfo({ address: address.value }).then(res => {
          if(res.success) {
            context.emit('getUserInfo', res.data)
          }
        })
      })*/
    }
  }
  async function onDisconnect() {
    console.log("Killing the wallet connection", provider);
    // TODO: Which providers have close method?
    if (address.value) {
      await web3Modal.clearCachedProvider();
      await provider.disconnect()
      provider = null;
      selectedAccount = null;
      address.value = '';
    }
  }
</script>

<style scoped>

</style>
