<template>
  <van-popup
      v-model:show="show"
      class="countryPop"
      position="right"
      :style="{ width: '100%', height: '100%' }"
      shape="round"
      duration="0.2"
  >
    <div class="country">
      <div class="search">
        <van-search
            v-model="text"
            placeholder="Search"
            action-text="Cancel"
            show-action
            :left-icon="require('@/assets/imgs/icon_search.png')"
            @update:model-value="handleChange"
            @cancel="handleCancel"
        />
      </div>
      <div class="list">
        <div
            v-show="!isEnter"
            v-for="(item,index) in codeList"
            :key="index"
            class="item"
            @click="handleClick(item)"
        >
          <div>{{ item.country_name }}</div>
          <div>{{ item.code }}</div>
        </div>
        <div
            v-show="isEnter"
            v-for="(item,index) in searchList"
            :key="index"
            class="item"
            @click="handleClick(item)"
        >
          <div>{{ item.country_name }}</div>
          <div>{{ item.code }}</div>
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { ref } from 'vue'
import { getCountryCode } from '@/request/api'

const show = ref(false)
const text = ref('')
const codeList = ref([])
getCountryCode().then(res => {
  if(res.success) {
    codeList.value = res.data
  }
})

const emits = defineEmits(['selected'])

function handleClick(item) {
  emits('selected', item)
  handleCancel()
}

const isEnter = ref(false)
const searchList = ref([])
function handleChange(v) {
  isEnter.value = true
  searchList.value = codeList.value.filter(item => {
    return item.country_name.toLowerCase().indexOf(v.toLowerCase()) > -1
  })
}

function handleCancel() {
  show.value = false
  isEnter.value = false
  text.value = ''
}

defineExpose({ show })

const customFieldName = {
  text: 'country_name',
  value: 'country_id'
}
</script>

<style lang="less" scoped>
.country{
  .search{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    width: 100%;
    top: 0;
    padding: 20px 20px 10px 20px;
    box-sizing: border-box;
    background-color: #000000;

    ::v-deep .van-icon__image{
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
  }
  .list{
    font-size: 16px;
    padding: 0 20px;
    height: calc(100vh - 90px);
    overflow-y: auto;

    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
    }
    .item:hover{
      color: #06C8A1;
    }
  }
}
</style>
