<template>
  <Connect @getContract="getContract"/>
  <div class="add">
    <van-nav-bar
        title="Card Activation"
        fixed
        placeholder
        left-arrow
        :border="false"
        @click-left="router.go(-1)"
    />
    <div class="main">
      <div class="form">
        <div class="item popup">
          <div class="label">Card Number</div>
          <input v-model="form.bank_card_code" class="first"/>
        </div>
        <div class="item popup">
          <van-popover v-model:show="showName" placement="right" theme="dark">
            <div class="tips small">As per passport.</div>
            <template #reference>
              <div class="label">
                <div>Card Name</div>
                <img class="info" src="@/assets/imgs/icon_info.png" alt="">
              </div>
            </template>
          </van-popover>
          <input v-model="form.u_name"/>
        </div>
        <div class="item popup">
          <van-popover v-model:show="showLogin" placement="right" theme="dark">
            <div class="tips">For log in to the product,<br/>your login credentials will<br/>be sent to the email address.</div>
            <template #reference>
              <div class="label">
                <div>Login E-mail</div>
                <img class="info" src="@/assets/imgs/icon_info.png" alt="">
              </div>
            </template>
          </van-popover>
          <input v-model="form.login_email"/>
        </div>
        <div class="item">
          <van-popover v-model:show="showContact" placement="right" theme="dark">
            <div class="tips">If you only have one email<br/>address, you can use it<br/>for all contact purposes.</div>
            <template #reference>
              <div class="label">
                <div>Contact E-mail</div>
                <img class="info" src="@/assets/imgs/icon_info.png" alt="">
              </div>
            </template>
          </van-popover>
          <input v-model="form.email"/>
        </div>
        <div class="item">
          <div class="label">International Mobile Number</div>
          <div class="value">
            <div class="select" @click="openCode">
              <div class="text">{{ areaCode }}</div>
              <img src="@/assets/imgs/icon_arrowS.png" alt="">
            </div>
            <input v-model="phone"/>
          </div>
        </div>
        <div class="item">
          <div class="label">Country</div>
          <div class="select" style="width: 100%;" @click="openCountry">
            <div class="text">{{ countryName }}</div>
            <img src="@/assets/imgs/icon_arrowS.png" alt="">
          </div>
        </div>
        <div class="item">
          <div class="label">Address Line 1</div>
          <input v-model="form.area_line_1"/>
        </div>
        <div class="item">
          <div class="label">Address Line 2</div>
          <input v-model="form.area_line_2"/>
        </div>
        <div class="item">
          <div class="label">City</div>
          <input v-model="form.city"/>
        </div>
        <div class="item">
          <div class="label">State/ Province/ Region</div>
          <input v-model="form.province"/>
        </div>
        <div class="item">
          <div class="label">Zip/ Postal Code</div>
          <input v-model="form.post_code"/>
        </div>
        <div class="item">
          <div class="label">Passport</div>
          <van-uploader
              v-model="fileList"
              image-fit="fill"
              :max-size="5 * 1024 * 1024"
              :max-count="1"
              accept="image/jpg,image/jpeg,image/png"
              :before-read="beforeRead"
              :after-read="afterRead"
              @oversize="onOversize"
          />
        </div>
      </div>
      <div class="btnWrap">
        <div class="btn" @click="router.go(-1)">Cancel</div>
        <div class="btn" @click="handleSubmit">Confirm</div>
      </div>
    </div>
  </div>

  <!--选择国际区号-->
  <CountryCode ref="countryCode" @selected="handleConfirm" />

  <!--选择国家弹框-->
  <Country ref="country" @selected="handleSelected"/>
</template>

<script setup>
  import { ref, reactive, onBeforeUnmount } from 'vue'
  import Connect from '@/components/Connect';
  import CountryCode from "@/components/CountryCode";
  import Country from '@/components/Country';
  import { uploadImg, saveUserAuth, getUserCardList } from "@/request/api";
  import Compressor from "compressorjs";
  import { showFailToast, showToast, showSuccessToast, showLoadingToast, closeToast } from "vant";
  import { useRouter } from 'vue-router';

  const router = useRouter()
  const showName = ref(false)
  const showLogin = ref(false)
  const showContact = ref(false)

  let oldVal = 0
  getUserCardList({
    address: sessionStorage.getItem('address')
  }).then(res => {
    if(res.success) {
      oldVal = res.data.length
    }
  })

  // 确认提交
  // let timer = null
  function handleSubmit() {
    if(form.bank_card_code === '') {
      showToast('Please Enter Card Number')
      return
    }
    if(form.u_name == '') {
      showToast('Please Enter Card Name')
      return
    }
    if(form.login_email == '') {
      showToast('Please Enter Login E-mail')
      return
    }
    if(form.email == '') {
      showToast('Please Enter Contact E-mail')
      return
    }
    if(areaCode.value == '') {
      showToast('Please select an international area code')
      return
    }
    if(phone.value == '') {
      showToast('Please Enter Mobile Number')
      return
    }
    if(form.country_id == '') {
      showToast('Please select a country')
      return
    }
    if(form.area_line_1 == '') {
      showToast('Please Enter Address Line 1')
      return
    }
    if(form.area_line_2 == '') {
      showToast('Please Enter Address Line 2')
      return
    }
    if(form.city == '') {
      showToast('Please Enter City')
      return
    }
    if(form.province == '') {
      showToast('Please Enter Province')
      return
    }
    if(form.post_code == '') {
      showToast('Please Enter Postal Code')
      return
    }
    if(form.purpose == '') {
      showToast('Please Upload Your Passport Photo')
      return
    }
    form.address = sessionStorage.getItem('address')
    form.mobile = areaCode.value + ' ' + phone.value
    let zuDisable = false
    saveUserAuth(form).then(async res => {
      if(res.success) {
        if(zuDisable) {
          return
        }
        zuDisable = true
        try {
          const res1 = await conContract.AddBankCard(res.data, {
            gasPrice,
            gasLimit: 400000
          })
          if(res1) {
            zuDisable = false
            setTimeout(() => {
              showSuccessToast('Operation Successful')
              router.push('/card')
            }, 1000)

            /*showLoadingToast({
              message: 'Loading...',
              forbidClick: true,
              duration: 0
            })*/
            /*timer = setInterval(async () => {
              const result = await getUserCardList({ address: sessionStorage.getItem('address') })
              if(result.success) {
                const newVal = result.data.length
                if(newVal > oldVal) {
                  clearInterval(timer)
                  closeToast()
                  showSuccessToast('Operation Successful')
                  router.push('/card')
                }
              }
            }, 1000)*/
          }
        } catch (e) {
          console.log(e)
          zuDisable = false
          showFailToast('Operation Failed')
        }
      } else {
        showToast(data.msg)
      }
    })
  }

  let conContract = null, gasPrice = ''
  function getContract(obj) {
    conContract = obj.conContract
    gasPrice = obj.gasPrice
  }

  const fileList = ref([])
  function beforeRead(file) {
    return new Promise(resolve => {
      new Compressor(file, {
        quality: 0.2,
        success(res) {
          const file = new File([res], res.name, { type: res.type })
          resolve(file)
        },
        error(err) {
          console.log(err.message)
        }
      })
    })
  }

  function afterRead(file) {
    const formData = new FormData();
    formData.append("file", file.file);
    uploadImg(formData).then(res => {
      if (res.success) {
        form.purpose = res.data.http_url;
      } else {
        showFailToast('Operation Failed')
      }
    })
  }

  const phone = ref('')
  const form = reactive({
    bank_card_code: '',
    u_name: '',
    login_email: '',
    email: '',
    mobile: '',
    country_id: '',
    area_line_1: '',
    area_line_2: '',
    city: '',
    province: '',
    post_code: '',
    purpose: ''
  })

  const countryCode = ref(null)
  function openCode() {
    countryCode.value.show = true
  }

  const areaCode = ref('')
  function handleConfirm(val) {
    areaCode.value = val.code
  }

  const country = ref(null)
  function openCountry() {
    country.value.show = true
  }

  function onOversize() {
    showFailToast('File size too big')
  }

  const countryName = ref('')
  function handleSelected(val) {
    countryName.value = val.country_name
    form.country_id = val.country_id
  }

  /*onBeforeUnmount(() => {
    clearInterval(timer)
  })*/
</script>

<style lang="less" scoped>
.add{
  .main{
    padding: 20px 24px 30px 24px;

    .form{
      margin-bottom: 30px;

      .item{
        margin-bottom: 26px;

        .label{
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          position: relative;

          .info{
            width: 12px;
            margin-left: 8px;
          }
        }
        input{
          width: 100%;
          height: 48px;
          border: 1px solid #697377;
          border-radius: 10px;
          box-sizing: border-box;
          font-size: 16px;
          color: #697377;
          padding-left: 14px;
          margin-top: 8px;
        }
        .first{
          border: 1px solid #06C8A1;
          background: rgba(6, 200, 161, 0.10);
          color: #ffffff;
        }
        .value{
          display: flex;
          justify-content: space-between;
          margin-top: 8px;

          input{
            width: calc(100% - 105px);
            margin-top: 0;
          }
        }
        .select{
          display: flex;
          align-items: center;
          width: 95px;
          height: 48px;
          border: 1px solid #697377;
          border-radius: 10px;
          color: #697377;
          background: #ffffff;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 10px 0 14px;

          .text{
            font-size: 16px;
            color: #697377;
          }
          img{
            width: 10px;
            height: 10px;
          }
        }
        >.select{
          margin-top: 8px;
        }
        ::v-deep .van-uploader{
          width: 100%;
          margin-top: 8px;

          .van-uploader__upload{
            width: 100%;
            margin: 0;
            border-radius: 10px;
            height: 180px;
          }
        }
      }
      .item:last-child{
        margin-bottom: 0;
      }
      .popup{
        margin-bottom: 10px;
      }
    }
    .btnWrap{
      display: flex;
      justify-content: space-between;
      padding: 0 46px;

      .btn{
        width: 92px;
        height: 36px;
        box-sizing: border-box;
        background: #697377;
        border-radius: 15px;
        border: 1px solid #ffffff;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .btn:hover{
        background: #06C8A1;
        border-color: #06C8A1;
      }
    }
  }
}
</style>
